<template>
  <div>
    <div class='flex-left' style='position: relative;top: 20px;'>
      <h3 class='text'>操作记录</h3>
      <el-link :disabled='loading' :loading='loading' style='margin: 10px 0' type='primary'
               @click='handleLogShow' :icon='logShow?"el-icon-upload2":"el-icon-download"'>{{ logShow ? '收起' : '展开' }}
      </el-link>
      <el-link :disabled='loading' :loading='loading' style='margin: 10px 0' type='primary'
           v-if="logShow"    @click='getLogsInquiryProject' icon='el-icon-refresh'>刷新
      </el-link>
    </div>
    <div v-if="userPermissions.indexOf('inquiry_project_log') !== -1&&logShow"
             shadow="never" style='max-height: 560px;overflow-y: scroll;width: clamp(500px, 50vw, 1200px)'>
      <div v-loading='loading'
           element-loading-text='加载中……'
           element-loading-spinner='el-icon-loading' style='height: 400px;overflow: scroll;padding: 10px'>
        <el-timeline class='opt-log' v-if='logShow'>
          <el-timeline-item
              :color='activity.color'
              :key='index'
              v-for='(activity, index) in divideLog'
          >
            {{ activity.created_at }}
            &nbsp; &nbsp; {{ activity.msg }}
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
<script>
import {mapGetters} from 'vuex'

export default {
  name: 'ProjectLog',
  props: {
    projectId: {
      type: [Number, String]

    }
  },

  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      logShow: false,
      loading: false,
      divideLog: []
    }
  },
  methods: {
    async getLogsInquiryProject() {
      this.divideLog = []
      this.loading = true
      this.divideLog = await this.$api.logsInquiry({ project_id: this.projectId })
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    showLog() {
      if (this.logShow) {
        this.getLogsInquiryProject()
      }

    },
    handleLogShow() {
      this.logShow = !this.logShow
      this.showLog()
    }
  },
  mounted() {
    this.showLog()
  }
}
</script>
<style lang="scss" scoped>
.flex-left {
  margin: 10px;
}
</style>