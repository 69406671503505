import { render, staticRenderFns } from "./InfoDetail.vue?vue&type=template&id=71fac660&scoped=true"
import script from "./InfoDetail.vue?vue&type=script&lang=js"
export * from "./InfoDetail.vue?vue&type=script&lang=js"
import style0 from "./InfoDetail.vue?vue&type=style&index=0&id=71fac660&prod&scoped=true&lang=css"
import style1 from "./InfoDetail.vue?vue&type=style&index=1&id=71fac660&prod&lang=scss"
import style2 from "./InfoDetail.vue?vue&type=style&index=2&id=71fac660&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71fac660",
  null
  
)

export default component.exports