<template>
  <div>
    <el-form ref='form' :inline='true' :model='form' label-width='80px' size='mini'>
      <el-form-item>
        <el-input placeholder='项目名称' v-model='form.name' clearable @change='onSubmit' @clear='onSubmit'></el-input>
      </el-form-item>
      <el-form-item v-if='isType'>
        <el-select v-model='form.intentions' multiple
                   placeholder='意向达人' @change='onSubmit'>
          <el-option v-for='(item,index) in pickerIntention' :key='index'
                     :label='item.nickname'
                     :value='item.id'>
            {{ item.nickname }}
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model='form.product_non_fuzzy' filterable clearable placeholder='请选择产品' @change='onSubmit'
                   @clear='onSubmit'>
          <el-option v-for='(item,index) in productOption' :key='index' :label='item?.name' :value='item?.value'>
            <span style='float: left'> {{ item?.name }} </span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="userPermissions.indexOf('inquiry_all_projects') !== -1">
        <el-select v-model='form.created_by' clearable filterable placeholder='请选择创建人' @change='onSubmit'
                   @clear='onSubmit'>
          <el-option
            v-for='item in createdData'
            :key='item.id'
            :label='item.nickname'
            :value='item.id'>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input placeholder='请填写品牌' v-model='form.brand' clearable @change='onSubmit'
                  @clear='onSubmit'></el-input>
      </el-form-item>
      <el-form-item>
        <el-cascader
          placeholder='请选择合作平台'
          :options='platforms'
          :props='props'
          clearable
          collapse-tags
          v-model='form.platforms'
          @change='handleChange'></el-cascader>
      </el-form-item>
      <el-form-item>
        <el-select v-model='form.status' clearable placeholder='请选择项目状态' @change='onSubmit' @clear='onSubmit'>
          <el-option
            label='进行中'
            value='1'>
          </el-option>
          <el-option
            label='已完结'
            value='2'>
          </el-option>
          <el-option
            label='已作废'
            value='3'>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type='primary' @click='onSubmit' :disabled='loading' :loading='loading'>查询</el-button>
        <el-button type='text' :icon='!showMore?"el-icon-caret-bottom":"el-icon-caret-top"'
                   @click='showMore=!showMore'>
          {{ !showMore ? '展开' : '隐藏' }}
        </el-button>
        <slot name='export'></slot>

      </el-form-item>

      <slot name='add'></slot>
      <div v-show='showMore'>
        <el-form-item>
          <el-select v-model='form.is_confirm' clearable placeholder='请选择下单状态' @change='onSubmit'
                     @clear='onSubmit'>
            <el-option
              label='已确认'
              value='1'>
            </el-option>
            <el-option
              label='未确认'
              value='0'>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model='form.is_apply' clearable placeholder='请选择报名状态' @change='onSubmit'
                     @clear='onSubmit'>
            <el-option
              label='报名中'
              value='1'>
            </el-option>
            <el-option
              label='不可报名'
              value='2'>
            </el-option>
            <el-option
              label='结束报名'
              value='3'>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model='created_at' :picker-options='pickerOptions' clearable
            end-placeholder='结束日期'
            placeholder='请选择创建日期'
            range-separator='至'
            start-placeholder='开始日期'
            type='daterange'
            value-format='yyyy-MM-dd'
            @change='onSubmit'
            @clear='onSubmit'>
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            placeholder='请选择期望档期'
            v-model='expect_at' :picker-options='pickerOptions' clearable
            end-placeholder='结束日期'
            range-separator='至'
            start-placeholder='开始日期'
            type='daterange'
            value-format='yyyy-MM-dd'
            @change='onSubmit'
            @clear='onSubmit'>
          </el-date-picker>
        </el-form-item>
      </div>


    </el-form>
    <hr style='background-color: #e4e7ed;height:1px; border:none;margin-bottom: 14px'>

  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'search',
  data() {
    return {
      loading: false,
      form: { platforms: [] },
      newEnd: '',
      platforms: [],//开通平台列表
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() + 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [end, start])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      pickerIntention: [],
      created_at: [],
      expect_at: [],
      props: { multiple: true, checkStrictly: false, emitPath: false, label: 'display_name', value: 'name' },
      createdData: [],
      showMore: false,
      productOption: []
    }
  },
  props: {
    isType: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleChange(value) {
      // console.log('value', value)
      // console.log('form.platforms',  this.form.platforms)
      // this.form.platforms = value
      // this.form.platforms = []
      // if (value?.length > 0) {
      //   value.forEach(item => {
      //     const len = item?.length
      //     if (len > 0) {
      //       this.form.platforms.push(item[len - 1])
      //     }
      //   })
      // }
      // console.log(this.form.platforms)
      this.$emit('searchData', this.form)
    },
    formatDateTime(date) {
      //时间改时间戳
      let afterDate = new Date(date).getTime() + (24 * 60 * 60 * 1000) // 后一天时间戳
      let dd = new Date(afterDate) // 时间戳转化成中国标准时间格式
      //格式化时间
      let y = dd.getFullYear()
      let m = dd.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      let d = dd.getDate()
      d = d < 10 ? ('0' + d) : d
      return y + '-' + m + '-' + d
    }
    ,
    onSubmit() {
      this.loading = true
      if (this.created_at?.length > 0) {
        this.form.created_start = this.created_at[0]
        this.form.created_end = this.formatDateTime(this.created_at[1])
      } else {
        this.form.created_start = ''
        this.form.created_end = ''
      }
      if (this.expect_at?.length > 0) {
        this.form.expect_start = this.expect_at[0]
        this.form.expect_end = this.expect_at[1]
      } else {
        this.form.expect_start = ''
        this.form.expect_end = ''
      }

      this.$emit('searchData', this.form)
      setTimeout(() => {
        this.loading = false
      }, 500)

    }
    ,
    async infoData() {
      let { list } = await this.$api.getCategoryListByIdentify(`inquiry-platforms`)
      let data = await this.$api.intentionInquiry()
      if (this.userPermissions.indexOf('inquiry_all_projects') !== -1) {
        this.createdData = await this.$api.allCreated()
      }
      if (!this.isType) {
        //如果是全案，直播平台只有抖音和小红书
        list = list.map((item) => {
          return item.name === 'douyin' || item.name === 'xiaohongshu' ? item : null
        }).filter(Boolean)
      }
      this.platforms = list
      this.pickerIntention = data
    },
    async productData() {
      let data = await this.$api.getInquiryProductOption({ type: this.isType ? 1 : 2 })
      this.productOption = data
    }
  },
  mounted() {
    this.infoData()
    this.productData()
  }
}
</script>

<style scoped>

</style>
