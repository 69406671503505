<template>
  <div>
    <div slot='header'>
      <div class='table_title' style='text-align: center'>
        <h2>{{ tableData.project_name || '' }}项目【{{ title }}】</h2>
      </div>
    </div>
    <br>
    <div class='flex-left' style='gap: 30px;font-size: 1.2em'>
      <div>
        <b>报名时间：</b>
        <span class="time">{{ tableData.expect_start }} 至 {{ tableData.except_end }}</span>
      </div>
      <div>
        <b>项目状态：</b>
        <el-tag
            :type=" tableData.status === 1 ? '' : tableData.status === 2 ? 'success' : tableData.status === 3 ? 'info' : '/'">
          {{
            tableData.status === 1 ? '进行中' : tableData.status === 2 ? '项目完结' : tableData.status === 3 ? '已作废' : '/'
          }}
        </el-tag>

      </div>
      <div>
        <b>报名状态：</b>
        <el-tag
            :type=" tableData.is_apply === 1 ? '' : tableData.is_apply === 2 ? 'success' : tableData.is_apply === 3 ? 'info' : ''">
          {{
            tableData.is_apply === 1 ? '报名中' : tableData.is_apply === 2 ? '不可报名' : tableData.is_apply === 3 ? '结束报名' : ''
          }}
        </el-tag>
      </div>
      <div><label style='font-weight: bold'> 报名 / 初选 / 合作人数：</label> <span>
       <span :class='[dimensionId===1?"curr time":""]'> {{ tableData.apply_count || 0 }} </span>
        /  <span :class='[dimensionId===2?"curr time":""]'> {{ tableData.employ_count || 0 }} </span>
        / <span :class='[dimensionId===3?"curr time":""]'> {{ tableData.confirm_count || 0 }} </span>
      </span>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'inquiriesTable',
  props: {
    tableData: {
      type: Object,
      required: true
    },
    title: {
      type: String
    },
    dimensionId: {
      type: [Number],
      required: true
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'FjallaOne';
  src: url("~@/assets/font/FjallaOne-Regular.ttf");
}

.curr {
  color: #ff3176;
}

.time {
  font-family: FjallaOne,serif;
}
</style>
