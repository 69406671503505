<template>
  <div style='padding: 20px 0;'>
    <el-tabs v-model='activeName'>
      <el-tab-pane label='询单信息' name='info'>
        <infoCard @infoPlatform='infoPlatform'></infoCard>
      </el-tab-pane>
      <el-tab-pane label='报名表' name='signUp'>
        <div v-if="activeName==='signUp'" class='default-table'>
          <!--      报名表-->
          <registration-table :tab-platforms='tabPlatforms' :project-id='projectId' :dimension-id='1'
                              v-if='projectId'></registration-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label='初选表' name='selection'>
        <!--      初选表-->
        <div v-if="activeName==='selection'" class='default-table'>

          <registration-table :tab-platforms='tabPlatforms' :project-id='projectId' :dimension-id='2'
                              v-if='projectId'></registration-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label='合作表' name='cooperate'>
        <!--      合作表-->
        <div v-if="activeName==='cooperate'" class='default-table'>
          <registration-table :tab-platforms='tabPlatforms' :project-id='projectId' :dimension-id='3'
                              v-if='projectId'></registration-table>
        </div>
      </el-tab-pane>
      <br>
    </el-tabs>

    <!--      日志 -->
    <ProjectLog :project-id='projectId' v-if="userPermissions.indexOf('inquiry_project_log') !== -1" />

    <br>
    <br>

  </div>
</template>

<script>
import infoCard from './components/infoCard'
import { mapGetters } from 'vuex'
import RegistrationTable from '@/pages/inquiries/components/RegistrationTable.vue'
import ProjectLog from '@/pages/inquiries/components/ProjectLog.vue'

export default {
  name: 'InfoDetail',
  components: {
    ProjectLog,
    RegistrationTable,
    infoCard
  },
  data() {
    return {
      projectId: '',
      loadingStatus: false,
      infoPlatforms: [],
      tabPlatforms: [],
      activeName: 'info',
      logShow: false
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  methods: {
    infoPlatform(val) {
      this.infoPlatforms = val
      if (val?.length > 0) {
        this.tabPlatforms = val.map((item) => {
          return item
        })
      }
    }
  },
  mounted() {
    this.projectId = this.$route.params.id
    sessionStorage.setItem('isTab', 'null')

  }
}
</script>
<style scoped>
.el-steps >>> .el-step {
  cursor: pointer !important;
}
</style>
<style lang='scss'>
.newForm {
  /*border: 1px solid red;*/
  .el-form-item__label {
    color: black;
    font-weight: 600;
  }

  .el-form-item--mini.el-form-item {
    margin-bottom: 13px;
  }
}

.title {
  font-size: 18px;
  font-weight: 800;
}

.download {
  color: #ff3176;
  margin-left: 10px;
  cursor: pointer;
}

.row {
  > h3 {
    display: flex;
    float: left;
    margin-right: 80px;
    text-align: center;
  }
}


</style>
<style lang='scss' scoped>

.text {
  font-size: 20px;
  background: -webkit-linear-gradient(-90deg, #e97ca1 30%, #ff3176);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.isAssign {
  font-size: 40px;
  display: inline-block;
  transform: rotate(45deg);
  z-index: 99;
  position: absolute;
  right: 0;
  top: 30px;
  background-color: #ffeaf1;
  background-size: 120%;
  color: #ff3176;
}

.text2 {
  /*width: 100px;*/
  height: 40px;
  border-radius: 4px;
  text-align: center;
  /*font-size: 30px;*/
  color: white;
  background: #ff3176;
  /*background-color:#e97ca1 ;*/
  font-size: 14px;
  line-height: 40px;
  cursor: pointer;
  margin-bottom: 10px;
  transition-delay: 1s;
  float: left;
  margin-right: 10px;
  padding: 0 10px;

}

.text2:hover {
  background-image: linear-gradient(to left, #ebb5c7, #ff3176); /*-webkit-text-fill-color: transparent;*/
}
</style>
