<template>
  <div>
    <el-card class='box-card'>
      <!--      <el-link icon='el-icon-refresh' type='primary' @click.stop.native='getInfo'>刷新</el-link>-->
      <!--      报名信息-基本信息-->
      <inquiriesHead v-if='tableData.info' :tableData='tableData.info' :title="`${dimensionMap[dimensionId]}`" :dimension-id='dimensionId'></inquiriesHead>
      <!--      报名信息-报名详情-->
      <div v-loading='loadingStatus'
           element-loading-text='加载中……'
           element-loading-spinner='el-icon-loading'>
        <el-tabs v-model='tabActive' @tab-click="changeTab()">
          <el-tab-pane :key='item.value' :label='item.name' :name='item.value' v-for='item in tabPlatforms'>
            <el-table :data='tableData.details'
                      @selection-change="handleSelectionChange($event)"
                      border
                      stripe
                      style='width: 100%'>
              <el-table-column
                type='selection'
                width='55'>
              </el-table-column>
              <el-table-column
                align='center'
                label='序号'
                type='index'
                width='55'>
              </el-table-column>
              <el-table-column
                align='center'
                key='ly'
                label='录用状态说明'
                min-width='110' prop='status'
              >
                <template slot-scope='{row}'>
                  <el-row>
                    录用状态：
                    <el-tag v-if='row.employ_status === 2' type='success'>已初选</el-tag>
                    <span v-else>已报名</span>
                    <el-tag style='margin-left: 10px' v-if='row.employ_status === 2&&row.confirm_status===2'>已合作
                    </el-tag>
                  </el-row>
                  <el-row>
                    合作意向: <span style=' color: #409EFF' v-if='row.intention===1'>试用</span>
                    <span style='color:#67C23A' v-if='row.intention===2'>可接</span>
                    <span style='color: #F56C6C' v-if='row.intention===3'>拒接</span>
                    {{ row.status === 3 ? row.reason : '' }}
                  </el-row>
                  <el-row>
                    意向说明：{{ row.intention_note || '暂无' }}
                  </el-row>
                  <el-row>
                    备注：{{ row.note || '暂无' }}
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column align="center" header-align="center" label="基本信息"
                               width="300">
                <template slot-scope="{row,$index}">
                   <span class="isAssign"
                         v-if="row.is_assign===1">定向</span>
                  <div v-if="row.plc_items&&row.plc_items.length>0">
                    <PanelKolInfo :data='row.plc_items[0]'
                                  :setting='row.plc_items[0]?.plc_type'
                                  :index='$index' style='height: 100%;margin-bottom: 8px;'/>
                    <PanelPlatformInfo v-if="row.plc_items[0]"
                                       :data="row.plc_items[0]"/>
                  </div>
                  <div v-else>{{ row.nickname }}</div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="当月价格" min-width="500"
                               prop="real_quote">
                <template slot-scope="{row}">
                  <div v-if="row?.plc_items">
                    <template v-for="(item,index) in row?.plc_items">
                      <span v-if="row?.plc_items" :key="index">
              <div v-if="item?.real_quote" class="price-div">
                    <div class="edit-box">
                  <PriceStructView :quote-obj='item?.real_quote' :platform-type="item.plc_type"/>
                    </div> <el-divider></el-divider>
              </div>
           </span></template>
                  </div>
                  <span v-else>暂无数据</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="商务案例" prop="bs_case" width="300">
                <template slot-scope="{row,$index}">
                  <div v-if="row?.plc_items">
                    <template v-for="(item,index) in row.plc_items">
                      <span :key="index">
                            <panel-bs-case :row='item' :row-id='$index'></panel-bs-case>
                                                    <el-divider></el-divider>
                      </span>
                    </template>
                  </div>
                  <span v-else>暂无数据</span>
                </template>
              </el-table-column>
              <el-table-column
                  label="授权费用（参考）"
                  min-width="150"
              >
                <template slot-scope="{row}">
                  <div v-for="item in empowerComp" :key="item.type">
                    <h4>
                      {{ item.type }}
                    </h4>
                    <span v-if="['抖音','快手'].includes(tabActive)">{{
                        item.data
                      }}</span>
                    <span v-if="['小红书','微博'].includes(tabActive)">
                        {{
                        row.newDetails?.fans_total && row.newDetails?.fans_total > 1000000 ? item.data['1000000'] : row.newDetails?.fans_total >= 500000 ? item.data['500000'] : row.newDetails?.fans_total >= 300000 ? item.data['300000'] : row.newDetails?.fans_total > 0 ? item.data['299999'] : '/'
                      }}
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  v-if="dimensionId===2||dimensionId===2"
                  label="消息通知"
                  width="110"
                  prop="confirm_notify">
                <template slot-scope="{row}">
                      <span v-if="row.employ_notify_count <= 0" style="color: blue">未通知 {{
                          row.apply_nickname
                        }}</span>
                  <div v-else style="color: #ff3176">已通知【 {{
                      row.apply_nickname
                    }} / {{ row.employ_notify_count }}次】
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  label="消息通知"
                  v-if="dimensionId===3||dimensionId===3"
                  width="100"
                  prop="confirm_notify">
                <template slot-scope="{row}">
                  <span v-if="row.confirm_notify_count <= 0" style="color: blue">未通知 {{
                      row.apply_nickname
                    }}</span>
                  <span v-else style="color: #ff3176">已通知【 {{
                      row.apply_nickname
                    }} / {{ row.confirm_notify_count }}次】</span>
                </template>
              </el-table-column>
              <el-table-column v-if="dimensionId===1" align="center" fixed="right" label="操作" width="150">
                <template slot-scope="{row}">
                  <div
                      v-if="(tableData.info.is_apply===1||tableData.info?.status !== 2)&&userPermissions.indexOf('inquiry_employ') !== -1">
                    <div :span="12" v-if="row.employ_status===1">
                      <el-link @click="changeStatus(row,'employ_status')" icon="el-icon-medal"
                               size="mini"
                               type="primary">
                        <span>进入初选</span>
                      </el-link>
                    </div>
                    <div :span="12" v-else-if="row.confirm_status!==2">
                      <el-link @click="changeStatus(row,'employ_status')" icon="el-icon-sunrise"
                               size="mini"
                               type="primary"
                      >
                        <span>取消初选</span>
                      </el-link>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" v-if="dimensionId===2" fixed="right" label="操作" width="150">
                <template slot-scope="{row}">
                  <el-row
                      v-if="(tableData.info.is_apply===1||tableData.info.status !== 2)&&userPermissions.indexOf('inquiry_employ') !== -1">
                    <el-col :span="12" v-if="row.confirm_status!==2">
                      <el-link @click="changeStatus(row,'employ_status')" icon="el-icon-sunrise"
                               size="mini"
                               type="primary">
                        <span>取消初选</span>
                      </el-link>
                    </el-col>
                    <el-col :span="12" v-if="row.confirm_status===1">
                      <el-link @click="changeStatus(row,'confirm_status')" icon="el-icon-trophy"
                               size="mini"
                               type="primary">
                        <span>合作</span>
                      </el-link>
                    </el-col>
                    <el-col :span="12" v-else>
                      <el-link @click="changeStatus(row,'confirm_status')" icon="el-icon-sunrise"
                               size="mini"
                               type="primary"
                      >
                        <span>取消合作</span>
                      </el-link>
                    </el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column align="center" v-if="dimensionId===3" fixed="right" label="操作" width="150">
                <template slot-scope="{row}">
                  <el-row
                      v-if="(tableData.info.is_apply===1||tableData.info.status !== 2)&&userPermissions.indexOf('inquiry_employ') !== -1">
                    <el-col :span="12" v-if="row.confirm_status===1">
                      <el-link @click="changeStatus(row,'confirm_status')" icon="el-icon-trophy"
                               size="mini"
                               type="primary">
                        <span>合作</span>
                      </el-link>
                    </el-col>
                    <el-col :span="12" v-else>
                      <el-link @click="changeStatus(row,'confirm_status')" icon="el-icon-sunrise"
                               size="mini"
                               type="primary"
                      >
                        <span>取消合作</span>
                      </el-link>
                    </el-col>
                  </el-row>
                </template>

              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
      <template v-if="tableData.info?.status!==2&&dimensionId===1">
        <el-row class="row-btn">
          <el-button
              size="medium"
              @click="handleVoid(1)"
              type="success"
              v-if="tableData.is_apply===3">
            开始报名
          </el-button>
          <el-button
              size="medium"
              @click="handleVoid(3)"
              type="warning"
              v-if="tableData.is_apply===1">
            结束报名
          </el-button>
          <el-button
              size="medium"
              @click="handleVoid(4)"
              type="danger"
              v-show="tableData.info?.status!==2&&userPermissions.indexOf('inquiry_finish') !== -1">
            项目完结
          </el-button>
          <el-button
              size="medium"
              @click="handleEmploy('employ')"
              type="success"
              v-if="userPermissions.indexOf('inquiry_employ') !== -1">
            批量初选
          </el-button>
          <el-row class="export-btn">
            <truck-button v-if="userPermissions.indexOf('inquiry_export_1') !== -1" @click="exportData()"
                          :text="'导出报名表'"></truck-button>
          </el-row>
        </el-row>
        <el-row>
          <el-button
              style="float:right;"
              @click="handleVoid(5)"
              type="warning"
              v-if="tableData.info?.status===2&&userPermissions.indexOf('inquiry_revocation') !== -1">
            <i class="el-icon-refresh-left"></i>
            撤回
          </el-button>
        </el-row>
      </template>
      <template v-if="tableData.info?.status!==2&&dimensionId===2">
        <el-row class="row-btn" v-if="tableData.status!==2">
          <el-button
              @click="handleEmploy('confirm')"
              size="medium"
              type="success"
              v-if="userPermissions.indexOf('inquiry_coop') !== -1">
            批量合作
          </el-button>
          <el-button
              @click="employNotify"
              size="medium"
              type="success"
              v-if="userPermissions.indexOf('inquiry_employ_notify') !== -1"
          >
            初选通知{{ employ_ids.length > 0 ? employ_ids.length + '人' : '' }}
          </el-button>
        </el-row>
        <el-row class="export-btn">
          <truck-button v-if="userPermissions.indexOf('inquiry_export_2') !== -1" @click="exportData()"
                        :text="'导出初选表'"></truck-button>
        </el-row>
      </template>
      <template v-if="tableData.info?.status!==2&&dimensionId===3">
        <el-row class="row-btn" v-if="tableData.info?.status!==2">
          <el-button
              @click="handleVoid(4)"
              type="danger"
              size="medium"
              v-show="tableData.status!==2&&userPermissions.indexOf('inquiry_finish') !== -1">
            项目完结
          </el-button>
          <el-button
              @click="confirmNotify"
              type="success"
              size="medium"
              v-if="userPermissions.indexOf('inquiry_confirm_notify') !== -1">
            合作通知{{ confirm_ids.length > 0 ? confirm_ids.length + '人' : '' }}
          </el-button>
          <el-row class="export-btn">
            <truck-button v-if="userPermissions.indexOf('inquiry_export_3') !== -1" @click="exportData()"
                          :text="'导出合作表'"></truck-button>
          </el-row>
        </el-row>
      </template>
    </el-card>

  </div>
</template>

<script>
import PanelPlatformInfo from '@/pages/inquiries/components/PanelPlatformInfo.vue'
import PanelKolInfo from '@/pages/inquiries/components/PanelKolInfo.vue'
import inquiriesHead from '@/pages/inquiries/components/inquiriesHead.vue'
import PriceGrids from '@/pages/publication/compoents/PriceGrids.vue'
import PriceStructView from "@/pages/publication/compoents/PriceStructView.vue";
import PanelBsCase from "@/pages/publication/compoents/PanelBsCase.vue";
import {mapGetters} from "vuex";
import FileSaver from 'file-saver'
import TruckButton from "@/components/Style/truckButton.vue";


export default {
  name: 'RegistrationTable',
  props: {
    projectId: {
      type: [Number, String],
      required: true
    },
    tabPlatforms: {
      type: Array,
      required: true
    },
    dimensionId: {
      type: [Number],
      required: true
    }
  },
  data() {
    return {
      loadingStatus:false,
      placeData: [],
      tableData: { info: {}, details: [] },
      //数据维度
      dimensionMap: {
        1: '报名表',
        2: '初选表',
        3: '合作表'
      },
      tabActive: null,
      empowerComp: {},
      employIds: [],
      //通知ids
      employ_ids: [],
      confirm_ids: [],
      detailsList: {}
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])

  },
  beforeDestroy() {
    // sessionStorage.setItem('isTab', null)
  },
  components: {
    TruckButton,
    PanelBsCase, PriceStructView,
    PriceGrids, inquiriesHead, PanelKolInfo, PanelPlatformInfo
  },
  async mounted() {
    //获取getList
    this.getTabActive()

    await this.getInfo()
    //获取授权费用参考
    await this.getOptions()
    await this.getCharging()
    //   sessionStorage.getItem(this.pageSearchKey)
  },

  methods: {
    getTabActive() {
      this.tabActive = this.tabPlatforms[0].value
    },
    changeTab() {
      this.tableData.details = []
      this.tableData.details = this.detailsList.map((item) => {
        return item.platform_code === this.tabActive ? item : false
      }).filter(Boolean)
    },
    handleSelectionChange(val) {
      this.employIds = []
      let type = this.dimensionId
      if (type === 1) {
        this.employIds = val.map((item) => item.id)
      } else if (type === 2) {
        this.employIds = val.map((item) => item.id)
        this.employ_ids = val.map((item) => item.artist_id)

      } else if (type === 3) {
        this.confirm_ids = []
        val.forEach((item) => {
          this.employIds.push(item.id)
          this.confirm_ids.push(item.artist_id)
        })
      }

      // this.apply_ids = []

    },
    async getInfo() {
      this.tableData = {}
      this.loadingStatus = true
      if (this.projectId) {
        //加载项目的报名信息
        let { info, details } = await this.$api.getProjectApplyDetailInfos({
          project_id: this.projectId,
          dimension: this.dimensionId
        })
        this.tableData.info = info || {}
        this.detailsList = [...details]
        //筛选当前tabs下的数据
        this.changeTab()
        // this.tableData.details = details || []
      }
      this.loadingStatus = false
    },
    async getCharging() {
      let {list} = await this.$api.chargingList()
      this.empowerData = list
      const tabActive = this.placeData.find(item => item.name === this.tabActive) || null;
      if (!Array.isArray(this.empowerData)) return;
      this.empowerComp = this.empowerData
          .find(item => item.type === tabActive.name)
          ?.content?.[tabActive.name];
    },
    async getOptions() {
      let {list} = await this.$api.getCategoryListByIdentify(`inquiry-platforms`)
      this.placeData = list || []
    },
    changeStatus(val, type) {
      let msg = ''
      let endMsg = ''
      let searchCondition = {}
      switch (type) {
        case 'employ_status':
          if (val.employ_status === 1) {
            msg = '进入初选'
            endMsg = '进入初选成功'
            searchCondition = {
              detail_id: val.id,
              project_id: this.projectId,
              employ_status: 2
            }
          } else {
            msg = '取消初选'
            endMsg = '取消初选成功'
            searchCondition = {
              detail_id: val.id,
              project_id: this.projectId,
              employ_status: 1
            }
          }
          break
        case 'confirm_status' :
          if (val.confirm_status === 1) {
            msg = '合作'
            endMsg = '合作成功'
            searchCondition = {
              detail_id: val.id,
              project_id: this.projectId,
              confirm_status: 2
            }

          } else {
            msg = '取消合作'
            endMsg = '取消合作成功'
            searchCondition = {
              detail_id: val.id,
              project_id: this.projectId,
              confirm_status: 1
            }

          }
          break
      }

      this.$confirm(`确认${val.nickname}【${msg}】?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let data = ''
        if (type === 'employ_status') {
          data = await this.$api.cancelStatus(searchCondition)
        } else if (type === 'confirm_status') {
          data = await this.$api.singleConfirm(searchCondition)
        }
        //
        if (data !== '') {
          this.$notify.success(endMsg)
          this.loadingStatus = true
          // location.reload()
          await this.getInfo()
        } else this.$notify.error('失败')
      }).catch(async () => {
        this.$notify.info('已取消')
        await this.getInfo()
      })
    },
    async exportData() {
      let type = this.dimensionId

      let searchCondition = {}
      if (type === 1) {
        searchCondition = {project_id: this.projectId}
      } else if (type === 2) {
        searchCondition = {project_id: this.projectId, employ_status: 2}
      } else if (type === 3) {
        searchCondition = {project_id: this.projectId, confirm_status: 2}
      }
      searchCondition = {
        ...searchCondition, dimension: this.dimensionId
      }

        try {
          let isFileSaverSupported = !!new Blob
          if (isFileSaverSupported) {
            // let searchCondition = { project_id: this.projectId, status: 2 }
            let response = await this.$api.exportApplyDetail(searchCondition)
            if (response.data) {
              let data = response.data
              let contentType = response.headers['content-type'] || ''
              let name = `${this.tableData.info.project_name}_${this.tableData.info.expect_start}至${this.tableData.info.except_end}_${type === '1' ? '报名表' : type === 'employ' ? '初选表' : '合作表'}`
              let blob = new Blob([data], {
                type: contentType
              })

              // name='demo:80%'// 欧莱雅母亲节礼盒（80%复颜水乳礼盒，20%黑精华礼盒）_2023-04-03至2023-04-04_报名表
              FileSaver.saveAs(blob, decodeURI(encodeURI(name) + '.xlsx'), {autoBom: true})
            } else {
              this.$message.warning(response.msg)
            }
          } else {
            this.$message.warning('浏览器不支持导出文件')
          }
        } catch (e) {
          this.$message.warning('导出异常，请联系管理员')
          // alert(JSON.stringify(e))
        }

    },

    handleEmploy() {
      let type = this.dimensionId
      if (this.employIds.length <= 0) {
        this.$notify.error('请选择录用人')
        return false
      }
      // confirmApply
      this.$confirm(`确认批量录用${type === 1 ? '初选' : type === 3 ? '合作' : ''}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let searchCondition
        let data = ''
        if (type === 1) {
          searchCondition = {ids: this.employIds, project_id: this.projectId, employ_status: 2}
          data = await this.$api.employApply(searchCondition)
        } else if (type === 2) {
          searchCondition = {ids: this.employIds, project_id: this.projectId, confirm_status: 2}
          data = await this.$api.confirmApply(searchCondition)
        }
        if (data !== '') {
          this.$notify.success('录用成功')
          await this.getInfo()
        } else this.$notify.error('失败')
      }).catch(async () => {
        this.$notify.info('已取消')
        await this.getInfo()
      })
    },
    async handleVoid(status) {
      let msg = ''
      let endMsg = ''
      let statusData = {}
      switch (status) {
        case 1:
          msg = '开启报名？'
          endMsg = '开始报名切换成功'
          statusData = {
            id: this.projectId,
            status: 1,
            is_apply: status
          }
          break
        case 3:
          msg = '结束报名？'
          endMsg = '结束报名切换成功'
          statusData = {
            id: this.projectId,
            status: 1,
            is_apply: status
          }
          break
        case 4:
          msg = '完结项目？'
          endMsg = '完结项目切换成功'
          statusData = {
            id: this.projectId,
            status: 2
          }
          break
        case 5:
          msg = '撤回项目？'
          endMsg = '撤回项目切换成功'
          statusData = {
            id: this.projectId,
            status: 1
          }
          break
      }

      if (status !== 4) {
        this.$confirm(`确定是否${msg}`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          // "status": 2 //1:进行中 2:已完结 3:已作废/ 4：彻底完结
          let id = await this.$api.switchInquiryStatus(statusData)
          if (id) {
            this.$notify.success(endMsg)
            await this.getInfo()
          }
        })
      } else {
        this.$confirm(`确定是否${msg}`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          // "status": 2 //1:进行中 2:已完结 3:已作废/ 4：彻底完结
          let id = await this.$api.endInquiryStatus(statusData)
          if (id) {
            this.$notify.success(endMsg)
            await this.getInfo()
          }
        })
      }
    }
    ,
    employNotify() {
      this.$confirm(`确定发送初选通知`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        //  artist_ids
        // "status": 2 //1:进行中 2:已完结 3:已作废/ 4：彻底完结
        let id = await this.$api.employNotify({project_id: this.projectId, artist_ids: this.employ_ids})
        if (id) {
          this.$notify.success('通知成功')
          await this.getInfo()
          // location.reload()
        }
      }).catch(() => {
            this.$notify.info('已取消')
          }
      )
    },
    confirmNotify() {
      this.$confirm(`确定发送合作通知`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // "status": 2 //1:进行中 2:已完结 3:已作废/ 4：彻底完结
        let id = await this.$api.confirmNotify({project_id: this.projectId, artist_ids: this.confirm_ids})
        if (id) {
          this.$notify.success('通知成功')
          await this.getInfo()

          // location.reload()
        }
      }).catch(() => {
            this.$notify.info('已取消')
          }
      )
    },
  }

}
</script>
<style lang="scss" scoped>


.text {
  font-size: 20px;
  background: -webkit-linear-gradient(-90deg, #e97ca1 30%, #ff3176);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



.text2:hover {
  background-image: linear-gradient(to left, #ebb5c7, #ff3176); /*-webkit-text-fill-color: transparent;*/
}
.isAssign {
  font-size: 40px;
  display: inline-block;
  transform: rotate(45deg);
  z-index: 99;
  position: absolute;
  right: 0;
  top: 30px;
  background-color: #ffeaf1;
  background-size: 120%;
  color: #ff3176;
}

.row-btn {
  margin: 20px 0;

  .export-btn {
    margin-top: 10px;
  }
}
</style>
